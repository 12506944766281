import request from "./request";
import { proxy as baseURL } from "@/config";

// 统计信息卡片
export const apiStatisticsCount = () => {
  return request({
    url: "/monitor/frontPage/statistics/count",
    method: "get",
    baseURL,
  });
};
// 抄表率
export const apiReadingMeter = () => {
  return request({
    url: "/monitor/frontPage/reading-meter/info",
    method: "get",
    baseURL,
  });
};
// 近7日通讯上报数对比
export const apiUploadCount = () => {
  return request({
    url: "/monitor/frontPage/upload-count",
    method: "get",
    baseURL,
  });
};

// 昨日top5用气量
export const apiUseGasTop5 = (params) => {
  return request({
    url: "/monitor/frontPage/amount-topfive",
    method: "get",
    params,
    // baseURL,
  });
};
// 近6个月用气量趋势(包含当月)
export const apiGasTrend = () => {
  return request({
    url: "/monitor/frontPage/amount-tendency",
    method: "get",
    // baseURL,
  });
};
// 抄表率弹窗信息
export const apiReadingMeterDetail = () => {
  return request({
    url: "/monitor/frontPage/reading-meter/detail",
    method: "get",
    baseURL,
  });
};

// 月设备发展量趋势
export const apiDeviceTrend = () => {
  return request({
    url: "/monitor/frontPage/meter-install-tendency",
    method: "get",
    baseURL,
  });
};

// 总数统计
export const apiDeviceStatis = (params) => {
  return request({
    url: "/monitor/ledger/statistics/count",
    method: "get",
    params,
    baseURL,
  });
};
// 月度告警类型top3
export const apiAlarmTop3 = (params) => {
  return request({
    url: "/monitor/ledger/statistics/alarm-type-device/top",
    method: "get",
    params,
    baseURL,
  });
};
// 设备发展量统计
export const apiDevelopCount = (params) => {
  return request({
    url: "/monitor/ledger/statistics/develop-device/count",
    method: "get",
    params,
    baseURL,
  });
};
// 重点告警汇总
export const apiHighAlarmCount = (params) => {
  return request({
    url: "/monitor/ledger/statistics/device/alarm/high-priority/info",
    method: "get",
    params,
    baseURL,
  });
};
// 重点告警汇总-看板
export const apiHighAlarmCountBoard = (params) => {
  return request({
    url: "/monitor/frontPage/alarm/high-priority/info",
    method: "get",
    params,
    baseURL,
  });
};
// 告警半年度汇总
export const apiHalfAlarmCount = (params) => {
  return request({
    url: "/monitor/ledger/statistics/device/alarm/info",
    method: "get",
    params,
    // baseURL,
  });
};
// 设备总数统计
export const apiDeviceTotal = (params) => {
  return request({
    url: "/monitor/ledger/statistics/device/count",
    method: "get",
    params,
    baseURL,
  });
};
// 设备大类在线率统计
export const apiCategoryOnline = (params) => {
  return request({
    url: "/monitor/ledger/statistics/firstcategory-online-device",
    method: "get",
    params,
    baseURL,
  });
};
// 离线设备总数统计
export const apiDeviceTotalOffline = (params) => {
  return request({
    url: "/monitor/ledger/statistics/offline-device/count",
    method: "get",
    params,
    baseURL,
  });
};
// 在线设备总数统计
export const apiDeviceTotalOnline = (params) => {
  return request({
    url: "/monitor/ledger/statistics/online-device/count",
    method: "get",
    params,
    baseURL,
  });
};
// 超年限设备总数统计
export const apiDeviceOverdue = (params) => {
  return request({
    url: "/monitor/ledger/statistics/overdue-device/count",
    method: "get",
    params,
    baseURL,
  });
};
// 高优先级告警设备列表
export const apiHighAlarmList = (params) => {
  return request({
    url: "/monitor/ledger/device/high-priority-alarm/list",
    method: "get",
    params,
    baseURL,
  });
};

export const apiAbnormalList = (data) => {
  return request({
    url: "/monitor/ledger/device/abnormal/list",
    method: "post",
    data,
    baseURL,
  });
};

// 远传小表数据统计
export const apiGetSmallMeterStatistics = (params) => {
  return request({
    url: "/monitor/frontPage/water/remote-watch/count",
    method: "get",
    params,
  });
};

//远传大表数据统计
export const apiGetBigMeterStatistics = (params) => {
  return request({
    url: "/monitor/frontPage/water/big-watch/count",
    method: "get",
    params,
  });
};

//设备列表
export const apiGetDeviceList = (params) => {
  return request({
    url: "/monitor/frontPage/water/waterEquipmentList/count",
    method: "get",
    params,
  });
};

//设备厂商
export const apiGetManufacturer = (params) => {
  return request({
    url: "/monitor/frontPage/water/equipmentManufacturer/count",
    method: "get",
    params,
  });
};

//小表抄见率
export const apiGetSmallWatchRate = (params) => {
  return request({
    url: "/monitor/frontPage/water/meterReadingRate/info",
    method: "get",
    params,
  });
};

//工商表水量
export const apiGetBusinessWater = (params) => {
  return request({
    url: "/monitor/frontPage/water/IndustryMonthUsed/info",
    method: "get",
    params,
  });
};

// 设备列表明细列表
export const apiGetWaterEquipmentList = (data) => {
  return request({
    url: "/monitor/frontPage/water/waterEquipmentList/detail",
    method: "post",
    data,
  });
};
