<template>
  <div class="water-home-device">
    <div class="water-home-title">设备列表</div>
    <div class="table-content">
      <div class="table-header">
        <div class="th" v-for="(item, index) in columns" :key="index">
          <p>{{ item.title }}</p>
        </div>
      </div>
      <div class="table-body">
        <div class="table-show" v-if="tableData.length > 0">
          <div class="tr" v-for="(item, index) in tableData" :key="index">
            <div class="td" v-for="(el, idx) in columns" :key="idx">
              <p
                @click="handleColumnClick(el, item)"
                :class="{
                  number: el.key !== 'waterRegionName' && item[el.key] > 0,
                  int: idx > 0,
                }"
              >
                {{ item[el.key] }}
              </p>
            </div>
          </div>
        </div>
        <gc-empty v-else></gc-empty>
      </div>
    </div>
    <WaterMeterTableList
      v-if="tableVisible"
      :currentRow="currentRow"
      @close-modal="tableVisible = false"
    />
  </div>
</template>

<script>
import WaterMeterTableList from "./WaterMeterTableList";
import { isBlank } from "@/utils/validate";
import { apiGetDeviceList } from "@/api/home";

export default {
  components: { WaterMeterTableList },
  props: {
    waterRegionCode: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      tableVisible: false,
      columns: [
        { title: "所属区域", key: "waterRegionName" },
        { title: "子区域数", key: "subWaterRegionCount" },
        { title: "居民表", key: "residentMeterCount", userType: 3 },
        { title: "工商表", key: "businessMeterCount", userType: 4 },
        { title: "考核表", key: "assessmentMeterCount", userType: 5 },
        { title: "流量计", key: "flowMeterCount", userType: 6 },
      ],
      tableData: [],
      currentRow: {},
    };
  },
  created() {},
  watch: {
    waterRegionCode(val) {
      if (!isBlank(val)) {
        this.getDeviceList(val);
      }
    },
  },
  methods: {
    // 设备列表
    getDeviceList(val) {
      apiGetDeviceList({
        waterRegionCode: val,
      }).then((res) => {
        this.tableData = res || [];
      });
    },
    // 列点击
    handleColumnClick(el, item) {
      if (item[el.key] && item[el.key] > 0) {
        if (el.key === "subWaterRegionCount") {
          // this.getDeviceList(item.waterRegionCode);
          this.$emit("change-code", item.waterRegionCode);
        } else {
          this.currentRow = {
            ...item,
            ...el,
            queryDimension: 0,
          };
          this.tableVisible = true;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.water-home-device {
  margin: 12px 0;
  .table-content {
    margin-top: 8px;
    .table-header {
      display: flex;
      margin-bottom: 6px;
      .th {
        flex: 1;
        padding: 12px;
        p {
          color: #282c42;
          font-size: 12px;
          font-weight: 700;
          line-height: 18px;
          text-align: center;
        }
      }
    }
    .table-body {
      max-height: 216px;
      overflow-y: scroll;
      .tr {
        display: flex;
        background-color: #f6f8ff;
        border-radius: 6px;
        & + .tr {
          margin-top: 12px;
        }
        .td {
          padding: 14px 12px;
          flex: 1;
          p {
            color: #3f435e;
            font-size: 14px;
            text-align: center;
            &.number {
              color: #4d6bff;
              font-family: DIN-Regular-2;
              text-decoration: underline;
              cursor: pointer;
            }
            &.int {
              font-size: 17px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1400px) {
  .water-home-device {
    .table-content {
      .table-body {
        max-height: 140px;
        .tr {
          & + .tr {
            margin-top: 6px;
          }
        }
      }
    }
  }
}
</style>
